import assertUnreachable from '../utils/assertUnreachable';
import { type IntrinsicUserAttributes, type LightdashUser } from './user';

export type Embed = {
    projectUuid: string;
    encodedSecret: string;
    dashboardUuids: string[];
    createdAt: string;
    user: Pick<LightdashUser, 'userUuid' | 'firstName' | 'lastName'>;
};

export type DecodedEmbed = Omit<Embed, 'encodedSecret'> & {
    encodedSecret: undefined;
    secret: string;
};

export type CreateEmbed = {
    dashboardUuids: string[];
};

export enum FilterInteractivityValues {
    some = 'some',
    all = 'all',
    none = 'none',
}

export type DashboardFilterInteractivityOptions = {
    enabled: FilterInteractivityValues | boolean;
    allowedFilters?: string[];
};

export type InteractivityOptions = {
    dashboardFiltersInteractivity?: DashboardFilterInteractivityOptions;
    canExportCsv?: boolean;
};

export type EmbedJwt = {
    userAttributes?: { [key: string]: string };
    user?: {
        externalId?: string;
    } & IntrinsicUserAttributes;
    content: {
        type: 'dashboard'; // we will add more types later
        dashboardUuid: string;
        isPreview?: boolean;
    } & InteractivityOptions;
    // JWT metadata
    iat?: number;
    exp: number;
};

export type CreateEmbedJwt = Pick<
    EmbedJwt,
    'content' | 'userAttributes' | 'user'
> & {
    expiresIn?: string;
};

export type EmbedUrl = {
    url: string;
};

// allows for backwards compatibility with old filter interactivity boolean values
export function getFilterInteractivityValue(
    enabled: DashboardFilterInteractivityOptions['enabled'],
) {
    if (typeof enabled === 'boolean') {
        return enabled
            ? FilterInteractivityValues.all
            : FilterInteractivityValues.none;
    }

    return enabled;
}

export function isFilterInteractivityEnabled(
    filterInteractivityOptions?: DashboardFilterInteractivityOptions,
) {
    if (!filterInteractivityOptions) return false;

    const filterInteractivityValue = getFilterInteractivityValue(
        filterInteractivityOptions.enabled,
    );

    switch (filterInteractivityValue) {
        case FilterInteractivityValues.some:
            return (
                filterInteractivityOptions.allowedFilters &&
                filterInteractivityOptions.allowedFilters.length > 0
            );
        case FilterInteractivityValues.all:
            return true;
        case FilterInteractivityValues.none:
            return false;
        default:
            return assertUnreachable(
                filterInteractivityValue,
                `Unknown FilterInteractivityValue ${filterInteractivityValue}`,
            );
    }
}
